import React, { useState } from 'react';
import logo from '../assets/images/EliteSecureGroupLogo2.png';
import menu from '../assets/images/icons-1.1.0/icons/menu.svg';
function NavigationBar() {
  return (
    <nav class="navbar navbar-expand-sm bg-black fixed-top">
        <div class="container">
            <div class="navbar-header">
                <a class="navbar-brand" href="/">
                    <img width="170px" height="120px" src={logo} alt="Elite Secure Group Logo"/></a>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <img src={menu} alt="" width="32" height="32" fill="currentColor"/>

            </button>
            <div class="navbar-collapse collapse" id="navbarCollapse">
                <ul class="nav navbar-nav pull-right justify-content-end">
                    <li class="active"><a class="nav-link" href="/">
                            <div class="blanco">Accueil</div>
                        </a></li>
                    <li class=" dropdown"><a class="nav-link dropdown-toggle" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Nos Services </a>
                        <div class="dropdown-menu blackbg">
                            <a class="dropdown-item blanco text-center" href="gardiennage-ronde-intervention">Sûreté et sécurité</a>
                            <a class="dropdown-item blanco text-center" href="securite-incendie">Sécurité
                                incendie</a>
                        </div>
                    </li>
                    <li class="nav-item"><a class="nav-link" href="references">Références</a></li>
                    <li class="nav-item"><a class="nav-link" href="contact">Contact</a></li>
                </ul>
            </div>
        </div>
    </nav>
  );
}

export default NavigationBar;