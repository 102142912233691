import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Footer() {
    return (
        <footer id="footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 widget">
                        <h3 class="widget-title">Contact</h3>
                        <div class="widget-body">
                            <p>
                                01 83 74 06 61<br />
                                <a href="mailto:elitesecure.group@gmail.com">elitesecure.group@gmail.com</a><br />
                                5 Av Georges Bataille, 60330 Le Plessis-Belleville
                            </p>
                        </div>
                    </div>

                    <div class="col-md-6 widget">
                        <h3 class="widget-title">Suivez-nous</h3>
                        <div class="widget-body">
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin fa-2x"></i> {/* Increased size to 2x */}
                                </a>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© 2024 Elite Secure Group. Tous droits réservés. | 
                        <a href="mentions-legales" className="mentions-link"> Mentions Légales</a> 
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;