import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

function Contact() {
    const position = [49.124032, 2.748677]; // Coordonnées de 5 Av Georges Bataille, 60330 Le Plessis-Belleville
    const address = "5 Av Georges Bataille, 60330 Le Plessis-Belleville";

    return (
        <>
            <header id="head2">
                <div className="container">
                    <div className="text-center">
                        <h1 className="lead mb-4">Contactez-nous</h1>
                        <h2 className="tagline"><b>Elite Secure <span className="blue-color">Group</span>: 01 83 74 06 61</b></h2>
                    </div>
                </div>
            </header>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="mb-4">Nos coordonnées</h2>
                        <p><strong>Adresse :</strong> 5 Av Georges Bataille, 60330 Le Plessis-Belleville</p>
                        <p><strong>Téléphone :</strong> 01 83 74 06 61</p>
                        <p><strong>Email :</strong> elitesecure.group@gmail.com</p>

                        <h3 className="mt-5 mb-3">Horaires d'ouverture</h3>
                        <p>Du lundi au vendredi : 9h00 - 18h00</p>
                        <p>Samedi, dimanche et en dehors des horraires d'ouverture: Astreinte téléphonique au 07 67 93 27 52</p>

                        <h3 className="mt-5 mb-3">Service d'urgence</h3>
                        <p>Disponible 24h/24 et 7j/7 pour nos clients sous contrat</p>
                        <p>Numéro d'Astreinte: 07 67 93 27 52</p>
                    </div>
                    <div className="col-md-6">
                        <h2 className="mb-4">Nous localiser</h2>
                        <MapContainer center={position} zoom={13} style={{ height: "400px", width: "100%" }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={position}>
                                <Popup>
                                    Elite Secure Group<br />
                                    {address}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="mb-4">Besoin d'un devis ou d'informations ?</h2>
                        <p>N'hésitez pas à nous contacter par téléphone ou par email. Notre équipe est à votre disposition pour répondre à toutes vos questions et vous proposer des solutions adaptées à vos besoins en matière de sécurité.</p>
                        <a href="tel:0183740661" className="btn btn-primary mt-3 me-3">Appelez-nous</a>
                        <a href="mailto:elitesecure.group@gmail.com" className="btn btn-outline-primary mt-3">Envoyez-nous un email</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;