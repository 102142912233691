import React from 'react';
import accesfiltrage from '../assets/images/agentsurveillance.webp'
import cynophile from '../assets/images/agentcinophilo.webp'
import ouverture from '../assets/images/ouverture.jpg'
function GardiennageRondeIntervention() {
    return (
        <>
            <header id="head">
                <div className="container">
                    <div className="text-center">
                        <h1 className="lead mb-4">Gestion du contrôle d'accès | Surveillance et Gardiennage | Rondes et interventions </h1>
                        <p className="tagline"><b>Elite Secure <span className="blue-color">Group</span></b>: Gardiennage et intervention pour la sécurité de vos biens et sites.</p>
                    </div>
                </div>
            </header>

            <div className="container text-center">
                <br /> <br />
                <h2 className="text-center bold">GARDIENNAGE & <span className="blue-color">RONDE</span></h2>
                <br />
                <p className="text-content">
                    Nous disposons d'agents de sécurité pour assurer le gardiennage et la surveillance de votre site. Nos agents sont formés et prêts à intervenir sur tout type de site ou événement :
                </p>
                <ul className="text-content">
                    <li>Gardiennage et surveillance de chantiers</li>
                    <li>Établissements recevant du public (ERP)</li>
                    <li>Établissements recevant des travailleurs (ERT)</li>
                    <li>Immeubles à usage d'habitation</li>
                    <li>Immeubles de moyennes et grandes hauteurs (IMH-IGH)</li>
                    <li>Événements</li>
                </ul>
            </div>

            <div className="jumbotron top-space w-100">
                <div className="container text-center">
                    <div id="services">
                        <h2 className="text-center bold">Missions de <span className="blue-color">Gardiennage et Sécurité</span></h2>
                    </div>
                    <br />
                    <div className="row text-center">
                        <div className="col-md-4">
                            <img src={ouverture} class="img-fluid mb-3" />
                            <h3>Ouverture et fermeture de sites</h3>
                            <p>Nos agents s'occupent de l'ouverture et de la fermeture sécurisée de vos sites, selon vos besoins spécifiques.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={accesfiltrage} class="img-fluid mb-3" />
                            <h3>Contrôle d'accès et filtrage</h3>
                            <p>Contrôle rigoureux des entrées et sorties pour garantir une sécurité optimale sur votre site.</p>
                        </div>
                        <div className="col-md-4">
                            <img src={cynophile} class="img-fluid mb-3" />
                            <h3>Rondes de sécurité</h3>
                            <p>Rondes régulières de jour comme de nuit, avec possibilité de rondes en véhicule pour les zones étendues.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container text-center">
                <br />
                <h2 className="text-center bold">Nos Agents de <span className="blue-color">Sécurité</span></h2>
                <br />
                <p className="text-content">
                    Le personnel de sécurité mis à disposition est formé et diplômé en fonction du poste à occuper et de la réglementation en vigueur. Nos agents de sécurité sont détenteurs d'une carte professionnelle en cours de validité, après avoir suivi une formation spécialisée telle que :
                </p>
                <ul className="text-content">
                    <li>CQP APS (Certificat de Qualification Professionnelle Agent de Prévention et de Sécurité)</li>
                    <li>TFP APS (Titre à Finalité Professionnelle Agent de Prévention et de Sécurité)</li>
                    <li>Formation d'agent cynophile - maître-chien</li>
                </ul>
                <p className="text-content">
                    N'hésitez pas à nous demander conseil pour toute demande de service de gardiennage, ronde ou intervention.
                </p>
            </div>
        </>
    );
}

export default GardiennageRondeIntervention;
