import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './assets/css/main.css';
import NavigationBar from './components/NavigationBar';
import Home from './screen/Home';
import Footer from './components/Footer';
import References from './screen/References';
import SecuriteIncendie from './screen/SecuriteIncendie';
import GardiennageRondeIntervention from './screen/GardiennageRondeIntervention';
import Contact from './screen/Contact';
import MentionsLegales from './screen/MentionsLegales';
function App() {
  return (
    <Router>
      <NavigationBar />
      <div className="home">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/references" element={<References />} />
          <Route path="/securite-incendie" element={<SecuriteIncendie />} />
          <Route path="/gardiennage-ronde-intervention" element={<GardiennageRondeIntervention />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
