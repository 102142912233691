import React from 'react';
import agentdesecuriteincendie from '../assets/images/agent-de-securite-incendie-2.webp'
import ssiap1 from '../assets/images/ssiap1.webp'
import ssiap2 from '../assets/images/ssiap2.webp'
import ssiap3 from '../assets/images/ssiap3.webp'

function SecuriteIncendie() {
    return (
        <>
            <header id="head2">
                <div class="container">
                    <div class="text-center">
                        <h1 class="lead mb-4">Sécurité Incendie</h1>
                        <p class="tagline"><b>Elite Secure <span class="blue-color">Group</span></b>: Protection et prévention pour votre tranquillité d'esprit</p>
                    </div>
                </div>
            </header>

            <div class="container text-center">
                <br /> <br />
                <h2 class="text-center bold">SÉCURITÉ <span class="blue-color">INCENDIE</span></h2>
                <br />
                <p class="text-content">
                    Les agents <strong>S.S.I.A.P</strong> (Service de Sécurité Incendie et d'Assistance aux Personnes) assurent la prévention et la sécurité incendie dans les <strong>ERP</strong> (établissements recevant du public) et les <strong>IGH</strong> (immeubles de grande hauteur).
                </p>
                <p class="text-content">
                    Nos agents de sécurité incendie sont tous titulaires du diplôme SSIAP de niveau 1 au minimum et du SST. <br /><br />Selon la réglementation et la sensibilité de votre site, nous pouvons mettre à votre disposition des agents SSIAP 1 (agent de sécurité incendie) ; SSIAP 2 (Chef d'équipe de sécurité incendie) et SSIAP 3 (Chef de service de sécurité incendie.
                </p>
                <br /><br />
                <img src={agentdesecuriteincendie} alt="SSIAP 1" class="img-fluid mb-3" />
                <br /><br />
                <p class="text-content">
                    Nos agents de sécurité incendie sont tous titulaires du diplôme SSIAP de niveau 1 au minimum et du SST. <br /><br />Selon la réglementation et la sensibilité de votre site, nous pouvons mettre à votre disposition des agents SSIAP 1 (agent de sécurité incendie) ; SSIAP 2 (Chef d'équipe de sécurité incendie) et SSIAP 3 (Chef de service de sécurité incendie.

                </p>
                <p className='text-content'>
                    Le personnel de sécurité incendie mis à disposition est formé et diplômé par rapport au poste à occuper, et à la réglementation en vigueur.<br /><br />

                    Selon vos besoins et/ou les spécificités de vos sites, nos agents SSIAP sont également formés à des procedures  tels que la désincarceration, l'évacuation horizontale, l'habilitation électrique<br /><br />

                    Pour la sécurité de votre site, mais également pour celle de l'agent, ce dernier doit avoir une habilitation électrique en adéquation avec ses missions et les risques de vos installations (H0-B0 ; BE manœuvre ; BS ...).

                </p>
            </div>

            <div class="jumbotron top-space w-100">
                <div class="container text-center">
                    <div id="services">
                        <h2 class="text-center bold">Les métiers de la <span class="blue-color">Sécurité Incendie</span></h2>
                    </div>
                    <br />
                    <div class="row text-center">
                        <div class="col-md-4">
                            <img src={ssiap1} alt="SSIAP 1" class="img-fluid mb-3" />
                            <h3>Agent de Sécurité Incendie (S.S.I.A.P. 1)</h3>
                            <p>Assure la prévention et la sécurité incendie au quotidien</p>
                            <a href="#ssiap1" class="btn btn-primary">En savoir plus</a>
                        </div>
                        <div class="col-md-4">
                            <img src={ssiap2} alt="SSIAP 2" class="img-fluid mb-3" />
                            <h3>Chef d'Équipe de Sécurité Incendie (S.S.I.A.P. 2)</h3>
                            <p>Encadre l'équipe de sécurité incendie sur site</p>
                            <a href="#ssiap2" class="btn btn-primary">En savoir plus</a>
                        </div>
                        <div class="col-md-4">
                            <img src={ssiap3} alt="SSIAP 3" class="img-fluid mb-3" />
                            <h3>Chef de Site de Sécurité Incendie (S.S.I.A.P. 3)</h3>
                            <p>Assure l'ensemble de l'aspect sécurité incendie du site</p>
                            <a href="#ssiap3" class="btn btn-primary">En savoir plus</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" id="ssiap1">
                <h2 class="text-center top-space">L'Agent de Sécurité Incendie (S.S.I.A.P. 1)</h2>
                <br />
                <div class="row">
                    <div class="col-md-12">
                        <p>Le travail de l'agent de sécurité incendie consiste surtout à faire de la prévention. Ses missions principales consistent à :</p>
                        <ul>
                            <li>vérifier qu'aucun acte de malveillance n'ait été commis sur le matériel incendie,</li>
                            <li>contrôler régulièrement les équipements de sécurité,</li>
                            <li>faire des rondes pour vérifier la sécurité de l'immeuble,</li>
                            <li>sensibiliser le personnel au risque incendie,</li>
                            <li>déclencher l'alarme,</li>
                            <li>prévenir et guider les secours,</li>
                            <li>sécuriser le bâtiment,</li>
                            <li>procéder à l'évacuation,</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="container" id="ssiap2">
                <h2 class="text-center top-space">Le Chef d'Équipe de Sécurité Incendie (S.S.I.A.P. 2)</h2>
                <br />
                <div class="row">
                    <div class="col-md-12">
                        <p>Le Chef d'Équipe de Sécurité Incendie a pour rôle d'encadrer son équipe de sécurité incendie sur site. Ses missions principales consistent à :</p>
                        <ul>
                            <li>s'assurer du respect de l'hygiène et de la sécurité du travail en matière de sécurité incendie;</li>
                            <li>manager son équipe de sécurité;</li>
                            <li>former du personnel en matière de sécurité contre l'incendie;</li>
                            <li>la prévision technique encadrée par les règlements de sécurité (lecture et manipulation des tableaux de signalisation, délivrance des permis feux…);</li>
                            <li>l'entretien élémentaire des moyens concourant à la sécurité incendie;</li>
                            <li>l'assistance à personnes au sein des établissements où ils exercent;</li>
                            <li>la direction du poste de sécurité lors des sinistres</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="container" id="ssiap3">
                <h2 class="text-center top-space">Le Chef de Site de Sécurité Incendie (S.S.I.A.P. 3)</h2>
                <br />
                <div class="row">
                    <div class="col-md-12">
                        <p>Le Chef de Site de Sécurité Incendie a pour rôle d'assurer l'ensemble de l'aspect sécurité incendie du site. Ses missions principales consistent à :</p>
                        <ul>
                            <li>encadrer et manager l'équipe de sécurité incendie</li>
                            <li>conseiller le chef d'établissement en matière de sécurité incendie</li>
                            <li>réaliser le suivi des obligations de contrôle ou d'entretien</li>
                            <li>organiser les secours internes</li>
                            <li>assister les personnes au sein de l'établissement</li>
                            <li>accueillir la commission de sécurité et lui rendre compte</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SecuriteIncendie;