import React from 'react';
import clients from '../assets/images/clients.webp'

import eiffage from '../assets/images/banner/eiffage.webp'
import eiffagec from '../assets/images/banner/eiffagec.png'
import eiffageg from '../assets/images/banner/eiffageg.png'
import ccn from '../assets/images/banner/ccn.png'
import bouygues from '../assets/images/banner/bouygues.webp'
import westbrook from '../assets/images/banner/westbrook.webp'
function References() {
    return (
        <>
            <section id="references" class="bg-light py-5">
                <div class="container">
                    <header class="text-center mb-5">
                        <h2 class="display-4">Nos Références</h2>
                        <p class="lead text-muted">Des clients satisfaits qui nous font confiance</p>
                    </header>
                    <div class="row">
                        <div class="col-md-6 mb-4">
                            <div class="card h-100 shadow-sm hover-card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between mb-3">
                                        <img src={eiffagec} class="img-fluid" alt="Image 1" style={{ maxWidth: "48%",height:'90px' }} /> {/* Adjust the width to fit */}
                                        <img src={eiffageg} class="img-fluid" alt="Image 2" style={{ maxWidth: "48%" }} /> {/* Adjust the width to fit */}
                                    </div>
                                    <p class="card-text">Gestion du contrôle d'accès multi-site.</p>
                                    <p class="card-text">Gardiennage 24/7</p>
                                    <p class="card-text">Collaboration depuis + de 10 ans</p>
                                    <p class="card-text">Rondes et Interventions multi-sites</p>
                                    <p class="card-text">Acteur des projets de la Société du Grand Paris</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4">
                            <div class="card h-100 shadow-sm hover-card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <img src={ccn} class="img-fluid" alt="Image 2" />
                                    </div>
                                    <p class="card-text">Etablissement medico-chirurgical (ERP Type U)</p>
                                    <p class="card-text">Personnel SSIAP.</p>
                                    <p class="card-text">Collaboration depuis + de 10 ans</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-4">
                            <div class="card h-100 shadow-sm hover-card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <img src={bouygues} class="img-fluid" alt="Image 3" />
                                    </div>
                                    <p class="card-text">Gestion du contrôle d'accès</p>
                                    <p className='card-test'>Gardiennage 24/7</p>
                                    <p className='card-test'>Rondes et Interventions multi-sites</p>
                                    <p class="card-text">Collaboration depuis + de 12 ans</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4">
                            <div class="card h-100 shadow-sm hover-card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <img src={westbrook} class="img-fluid" alt="Image 4" />
                                    </div>
                                    <p class="card-text">Gestion du contrôle d'accès de sites sensibles</p>
                                    <p class="card-text">Surveillance de sites</p>
                                    <p class="card-text">Intervention sur alarme</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class="row mt-4">
                        <div class="col-md-4 mb-4">
                            <div class="card h-100 shadow-sm hover-card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <i class="fas fa-user-tie fa-2x text-primary mr-3"></i>
                                        <h4 class="card-title mb-0">Client D</h4>
                                    </div>
                                    <p class="card-text">Surveillance d'événements privés et gestion des accès VIP.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="card h-100 shadow-sm hover-card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <i class="fas fa-hard-hat fa-2x text-primary mr-3"></i>
                                        <h4 class="card-title mb-0">Client E</h4>
                                    </div>
                                    <p class="card-text">Sécurisation de chantiers, surveillance des équipements et des
                                        matériaux.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-4">
                            <div class="card h-100 shadow-sm hover-card">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-3">
                                        <i class="fas fa-concierge-bell fa-2x text-primary mr-3"></i>
                                        <h4 class="card-title mb-0">Entreprise F</h4>
                                    </div>
                                    <p class="card-text">Services d'accueil sécurisés pour un centre d'affaires international.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <section id="about-references">
                <div class="container text-center">
                    <h2 class="bold">Acteur de la sécurité privée depuis 2010</h2>
                    <p>
                        <span class="bold">ELITE SECURE <span class="blue-color">GROUP</span></span> a su au fil des années créer une relation de confiance avec l'ensemble de ses clients et
                        développer des partenariats de plus en plus importants.
                    </p>
                    <p>
                        Nous assurons la sécurité et l'accueil sur tout types de sites : Chantiers, Bureaux, Magasins, ERP, IGH,
                        Parkings, Évènementiels, Gares, etc.
                    </p>
                    <img src={clients} alt="Clients Elite Secure Group" class="img-fluid mb-4" />
                    <p class="text-muted">Ces organismes ont choisi Elite Secure Group afin d'assurer la sécurité de leurs sites.
                        Comme eux, faites-nous confiance et détaillez-nous directement votre besoin en sécurité afin que nous
                        puissions vous accompagner.</p>
                </div>
            </section>
        </>
    );
}

export default References;