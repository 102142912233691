import React from 'react';

function MentionsLegales() {
    return (
        <div className="container mentions-legales">
            <h1 className="text-center mt-5">Mentions Légales</h1>
            <p>Conformément aux dispositions des articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs du site Elite Secure Group les présentes mentions légales.</p>

            <h2>1. Informations légales</h2>
            <p>
                Raison sociale : Elite Secure Group<br />
                Adresse : 5 Av Georges Bataille, 60330 Le Plessis-Belleville<br />
                Téléphone : 01 83 74 06 61<br />
                Email : elitesecure.group@gmail.com<br />
                SIRET : 952 205 540 00018<br />
            </p>

            <h2>2. Hébergement du site</h2>
            <p>
                Hébergeur : Netlify<br />
                Adresse : 610 22nd Street, Suite 315 CA 94107 San Francisco<br />
                Téléphone : +1 844-899-7312<br />
            </p>

            <h2>3. Propriété intellectuelle</h2>
            <p>Le site, son contenu, son graphisme, et tous les éléments qui le composent (logos, images, textes, etc.) sont la propriété exclusive d'Elite Secure Group ou de ses partenaires. Toute reproduction ou utilisation de ces éléments sans autorisation est strictement interdite.</p>

            <h2>4. Données personnelles</h2>
            <p>Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée et au RGPD, l’utilisateur dispose d’un droit d’accès, de rectification, et d’opposition aux données personnelles le concernant. Pour exercer ce droit, merci de nous contacter à l’adresse suivante : elitesecure.group@gmail.com.</p>

            <h2>5. Limitations de responsabilité</h2>
            <p>Elite Secure Group s'efforce de fournir des informations aussi précises que possible sur son site, mais ne saurait garantir l'exactitude ou l'exhaustivité des informations diffusées. En conséquence, l'utilisateur reconnaît utiliser ces informations sous sa responsabilité exclusive.</p>

            <h2>6. Liens hypertextes</h2>
            <p>Le site Elite Secure Group peut contenir des liens hypertextes vers d'autres sites. Elite Secure Group décline toute responsabilité concernant le contenu de ces sites tiers.</p>

            <h2>7. Droit applicable</h2>
            <p>Tout litige en relation avec l’utilisation du site Elite Secure Group est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents.</p>

        </div>
    );
}

export default MentionsLegales;
