import React, { useState } from 'react';
import agentimg from '../assets/images/agentdesecurite.webp'
import intervenant from '../assets/images/intervenant.webp'
import agentaccueil from '../assets/images/agentaccueil.webp'
import rondier from '../assets/images/banner/rondier22.png'
import agentdesecuriteincendie from '../assets/images/agentdesecuriteincendie.webp'
import eiffageLogo from '../assets/images/banner/eiffage.webp'
import westbrookLogo from '../assets/images/banner/westbrook.webp'
import bouyguesLogo from '../assets/images/banner/bouygues.webp'
import actionLogo from '../assets/images/banner/al.webp'
import cdef from '../assets/images/banner/cdef.webp'
import rambouillet from '../assets/images/banner/rambouillet.webp'
import razelbec from '../assets/images/banner/razelbec.webp'
import solendi from '../assets/images/banner/solendi.webp'
import { Carousel } from 'react-bootstrap';

function Home() {
    const clientPairs = [
        [{ name: 'Eiffage', logo: eiffageLogo }, { name: 'Westbrook Partners', logo: westbrookLogo }],
        [{ name: 'Bouygues Construction', logo: bouyguesLogo }, { name: 'Action', logo: actionLogo }],
        [{ name: 'CDEF', logo: cdef }, { name: 'Rambouillet Territoires', logo: rambouillet }],
        [{ name: 'Razelbec', logo: razelbec }, { name: 'Solendi', logo: solendi }],
      ];
    return (
        <>
            <header id="head">
                <div class="container">
                    <div class="text-center">
                        <h1 class="lead mb-4">Votre Sécurité, Notre Priorité</h1>
                        <p class="tagline"><b>ELITE SECURE <span class="blue-color">GROUP</span> </b>: Sûreté et sécurité | Gardiennage | Rondes et Intervention | Sécurité Incendie | Accueil</p>
                    </div>
                </div>
            </header>

            <div class="container text-center">
                <br /> <br />
                <h2 class="text-center bold">ELITE SECURE <span class="blue-color">GROUP</span></h2>
                <br />
                <p class="text-content">

                    <b>Fort d'une expérience depuis 2010</b>, <b>ELITE SECURE <span class="blue-color">GROUP</span></b>, a su au fil des années
                    convaincre de grandes entités d'assurer la <b>sécurité</b> de leurs sites. <br /> <br />

                    <b>Notre principal atout</b> s'exprime dans la prise en considération de la constante évolution de notre secteur
                    d'activité et ainsi de pouvoir mettre à disposition un <b>personnel</b> ayant les compétences nécessaires et attendues sur chaques postes. <br /> <br />

                    <b>Afin de garantir ce niveau de prestation</b>, <b>ELITE SECURE <span class="blue-color">GROUP</span> </b> a su se constituer un centre décisionnaire
                    compétent qui s'assure de la bonne exécution de nos prestations. <br /> <br />

                    <b>Notre principale motivation</b> sont la satisfaction de notre clientèle, et nous mettons tout en oeuvre afin que
                    nos prestations répondent à leurs attentes. <br /> <br />

                    <b>Notre fierté</b> s'exprime également à travers la fidélité de nos collaborateurs, en qui la <b>confiance</b>, 
                    la <b>considération</b> et le <b>respect</b> sont des valeures mutuelles.
                </p>
            </div>

            <div class="jumbotron top-space w-100">
                <div class="container text-center">
                    <div id="services">
                        <h2 class="text-center bold">Nos <span class="blue-color">Prestations</span></h2>
                    </div>
                    <br />
                    <div class="row text-center">
                        <div class="col-md-4">
                            <img src={agentimg} alt="Agent de sécurité" class="img-fluid mb-3" />
                            <h3>Sécurité et Gardiennage</h3>
                            <p>Protection des sites, contrôle d'accès, rondes de surveillance</p>
                        </div>
                        <div class="col-md-4">
                            <img src={intervenant} alt="Agent d'intervention" class="img-fluid mb-3" />
                            <h3>Intervention</h3>
                            <p>Équipes d'intervention rapide, gestion de situations d'urgence</p>
                        </div>
                        <div class="col-md-4">
                            <img src={agentaccueil} alt="Agent d'accueil" class="img-fluid mb-3" />
                            <h3>Accueil</h3>
                            <p>Services d'accueil professionnel pour entreprises et événements</p>
                        </div>
                    </div>
                    <div class="row text-center mt-4">
                        <div class="col-md-6">
                            <img src={agentdesecuriteincendie} alt="Agent de sécurité incendie" class="img-fluid mb-3" />
                            <h3>Sécurité Incendie</h3>
                            <p>Prévention et intervention en cas d'incendie, formation du personnel</p>
                        </div>
                        <div class="col-md-6">
                            <img src={rondier} alt="Agent rondier" class="img-fluid mb-3" width="350px" />
                            <h3>Rondes de Sécurité</h3>
                            <p>Surveillance mobile, vérification des points de contrôle, rapports détaillés</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container" id="about">
                <h2 class="text-center top-space">À Propos d'<span class="bold">Elite Secure <span
                    class="blue-color">Group</span></span></h2>
                <br />
                <div class="row">
                    <div class="col-md-6">
                        <h4>Notre Mission</h4>
                        <p>Assurer la sécurité et la tranquillité de nos clients grâce à des solutions innovantes et un
                            personnel hautement qualifié.</p>
                    </div>
                    <div class="col-md-6">
                        <h4>Nos Valeurs</h4>
                        <ul>
                            <li>Professionnalisme</li>
                            <li>Intégrité</li>
                            <li>Réactivité</li>
                            <li>Innovation</li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="client-carousel-section py-5">
      <div className="container">
        <h2 className="text-center mb-4">Ils nous font <span className="text-primary">confiance</span></h2>
        <Carousel 
          indicators={false}
          interval={2000}
          pause={false}
          className="custom-carousel"
        >
          {clientPairs.map((pair, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex justify-content-around align-items-center">
                {pair.map((client, clientIndex) => (
                  <div key={clientIndex} className="client-logo-container">
                    <img
                      src={client.logo}
                      alt={client.name}
                      className="client-logo"
                    />
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <p className="text-center mt-4">
          Ces organismes ont choisi <strong>Elite Secure Group</strong> pour assurer la sécurité de leurs sites.<br />
          Comme eux, faites-nous confiance et <a href="/contact" className="text-primary">expliquez-nous directement vos besoins</a>.
        </p>
      </div>
    </div>

        </>
    );
}

export default Home;